// .classList.fn.() polyfills
import 'classlist-polyfill';

// .forEach() polyfill
if (typeof NodeList.prototype.forEach == "undefined") {
    NodeList.prototype.forEach = function (fn) {
        Array.prototype.forEach.call(this, fn);
    };
}
// .closest() polyfill
if (typeof HTMLElement.prototype.closest == "undefined") {
    HTMLElement.prototype.closest = function closest(property, value){
        let el = this;
        while (el = el.parentElement) {
            if (el[property] == value) return el;
        }
        return null;
    }
}

// .append() polyfill
// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/append()/append().md
(function (arr) {
    arr.forEach(function (item) {
        if (item.hasOwnProperty('append')) {
            return;
        }
        Object.defineProperty(item, 'append', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function append() {
                var argArr = Array.prototype.slice.call(arguments),
                        docFrag = document.createDocumentFragment();

                argArr.forEach(function (argItem) {
                    var isNode = argItem instanceof Node;
                    docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
                });

                this.appendChild(docFrag);
            }
        });
    });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);
